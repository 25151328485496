<template>
    <div class="with">
        <div class="query">
            <Form
            ref="form"
            :formSchema="withformSchema"
            >
                <li style="width: 20%" class="pl20">
                    <el-button type="primary" @click="onAjax">查询</el-button>
                    <el-button @click="onReset">重置</el-button>
                    <el-button type="primary" @click="onExport">导出</el-button>
                </li>
            </Form>
        </div>
        <div class="mt10 table-layout">
            <Table
            :tableFields="withdrawalFields"
            :tableData="tableData"
            :total="total"
            @handleCurrentChange="handleCurrentChange"
            >
                <template #status_name="{ row }">
                    <span :class="`status-${row.status}`">{{ row.status_name }}</span>
                </template>
                <template #operate="{ row }">
                    <!-- <el-dropdown v-if="row.status == 1" trigger="click" @command="onPayment($event, row)">
                        <span class="el-dropdown-link link b1">打款</span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="success">打款成功</el-dropdown-item>
                            <el-dropdown-item command="fail">打款失败</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                    <el-button v-if="row.status == 1 || row.status == 4" type="text" class="link b1" @click="onPayment(row)">打款</el-button>
                    <el-button v-if="row.status == 1 || row.status == 4" type="text" class="link b2" @click="onBack(row)">退回</el-button>
                    <!-- <span v-if="row.status == 3">--</span> -->
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
    import { withdrawalFields, withformSchema } from './index'
    import helper from '@/common/helper.js';
    import XLSX from "xlsx"
    export default {
        data(){
            return {
                withformSchema,
                total: 0,
                tableData: [],
                withdrawalFields,
                loading: true,
                form: {
                    page: 1,
                    limit: 10,
                }
            }
        },
        created(){
            
        },
        mounted(){
            this.onAjax()
        },
        methods: {
            onReset(){
                this.$refs.form.resetFields()
                this.onAjax()
            },
            handleCurrentChange(page){
                console.log(page);
            },
            onAjax(){
                this.$refs.form.validate((is, data)=>{
                    if(is){
                        const params = helper.timerHandle(data, 'timer', 'start_date', 'end_date')                    
                        this.$http.api('api/Agent/AgentWithdrawal', {...params, ...this.form})
                        .then(res=>{
                            this.total = res.data.total
                            this.tableData = res.data.data
                        })
                        .finally(()=>{
                            this.loading = false
                        })
                    }
                })
            },
            onPayment({id}){
                this.$http.api('api/Agent/AgentWithdrawalSuccess', { id })
                .then(res=>{
                    this.$message.success(res.msg)
                    this.onAjax()
                })
                // if(command == 'success'){
                //     this.$http.api('api/Agent/AgentWithdrawalSuccess', { id })
                //     .then(res=>{
                //         this.$message.success(res.msg)
                //         this.onAjax()
                //     })
                // }
                // if(command == 'fail'){
                //     this.$http.api('api/Agent/AgentWithdrawalFail', { id })
                //     .then(res=>{
                //         this.$message.success(res.msg)
                //         this.onAjax()
                //     })
                // }
            },
            onBack({ id }){
                this.$http.api('api/Agent/AgentWithdrawalReturn', { id })
                .then(res=>{
                    this.$message.success(res.msg)
                    this.onAjax()
                })
            },
            
            onExport(){
                this.$http.api('api/Agent/AgentWithdrawalExct')
                .then(res=>{
                    const fields = withdrawalFields.filter(item=> item.prop!= 'operate')
                    const header = fields.map(item=> item.label)
                    const all =  res.data.map(item=>{
                        return fields.map(i=> item[i.prop])
                    })
                    const data = [
                        header,
                        ...all,
                    ];
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(data,  {
                        header: data.length > 0 ? Object.keys(data[0]) : [],
                        skipHeader: true
                    });

                    // 设置列宽
                    const wscols = fields.map((width, index) => ({ wch: 15 }));
                    worksheet['!cols'] = wscols;

                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                    XLSX.writeFile(workbook, "record.xlsx");
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .query{
        background: #fff;
        padding: 7px 20% 7px 0;
        flex-shrink: 0;
    }
    .link{
        color: #fff;
        font-size: 12px;
        padding: 2px 6px;
        border-radius: 3px;
        margin: 0 4px;
        &.b1{
            background: #ff9900;
        }
        &.b2{
            background: #e74c3c;
        }
    }
    .with{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .table-layout{
        flex-grow: 1;
    }
    .status-1{color: green;}
    .status-2{color: green;}
    .status-3{color: #F60;}
    .status-4{color: red;}
</style>